import React, { useRef } from "react"
import "./QRModal.css"
import "./QRModalAlt.css"
import QRCode from "qrcode.react"
import { useOutsideClick } from "../../misc/utils"
import { VARIANT } from "../../misc/constants"

function QRModal({ show, setShow, totp }) {
    const modalRef = useRef(null)

    const handleClose = () => {
        setShow(false)
    }

    useOutsideClick([modalRef], handleClose)

    return (
        <div className={`qr-modal-bg ${show ? "visible" : "hidden"}`}>
            <div
                ref={modalRef}
                className={`qr-modal-content${VARIANT} ${show ? "visible" : "hidden"}`}
            >
                <div
                    data-testid={"qr-close-modal"}
                    className={"qr-modal-close" + VARIANT}
                    onClick={handleClose}
                >
                    &times;
                </div>
                <div style={{ height: "40px" }}></div>
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <div className={"qr-code-wrapper" + VARIANT}>
                        <QRCode value={totp} size={200} />
                    </div>
                </div>
                <p className={"qr-text" + VARIANT}>
                    Google Authenticator and Microsoft Authenticator are free to
                    download via the Apple App Store or the Google Play Store.
                    Enable biometric authentication for added security.
                </p>
                <img className="app-store" src="/App Store.png"></img>
            </div>
        </div>
    )
}

export default QRModal
