import React from "react";

import StatusMsg from "../../StatusMsg/StatusMsg"

import './SendSMSView.css';
import './SendSMSViewAlt.css';

import { VARIANT } from "../../../misc/constants";

const SendSMSView = (props) => {
    const handleBack = props.handleBack
    return (
        <div>
            <p className={"view-header" + VARIANT}>Authenticate via SMS</p>
            <StatusMsg statusMsg={props.statusMsg} />
            <p className={"sms-info" + VARIANT}>
                Please click the button below to get an authentication code sent to <strong>{props.phoneNumber}</strong>.
                If you no longer have access to your phone, please contact your administrator to reset your account.
            </p>
            <div style={handleBack ? {height: '60px'} : {height: '110px'}}></div>
            <button data-testid="sendSMS-proceed-button" className={"proceed" + VARIANT} onClick={props.sendSMS}>Send SMS</button>
            {/* In the case of forgot password, this cancels it */}
            {handleBack && <button data-testid="sendSMS-cancel-button" onClick={handleBack} className={"cancel" + VARIANT}>Cancel</button>}
        </div>
    )
};

export default SendSMSView;