import React, { useEffect, useState } from "react";

import PasswordInput from "../../PasswordInput/PasswordInput";

import './UpdatePasswordView.css';
import './UpdatePasswordViewAlt.css';
import ErrorMsg from "../../ErrorMsg/ErrorMsg";
import { VARIANT } from "../../../misc/constants";


const UpdatePasswordView = (props) => {
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [validationChars, setValidationChars] = useState(false);
  const [validationMatch, setValidationMatch] = useState(false);

  useEffect(() => {
    props.resetStatusMsg()
  }, [password1, password2])

  const arePasswordsAligned = (newPassword1, newPassword2) => {
    /**
     * Returns true if misaligned -> make text red
     * Returns null if equal -> make text green
     * Return null if undecided
     */
    const pwd1Chars = newPassword1.split('');
    const pwd2Chars = newPassword2.split('');
    if (newPassword1 === newPassword2) { return true }
    for (let idx=0; idx < pwd2Chars.length; idx++) {
      if (pwd1Chars[idx] !== pwd2Chars[idx]) { return null }
    }
    return false;
  }

  const handlePasswordChange = (event) => {
    const isPassword1 = /password1/.test(event.target.className)
    const newValue = event.target.value;
    const otherValue = isPassword1 ? password2 : password1;
    if (isPassword1) { setValidationChars(newValue.length >= 8) }
    setValidationMatch(arePasswordsAligned(
      isPassword1 ? newValue : otherValue,
      isPassword1 ? otherValue : newValue)
    );
    if (isPassword1) { setPassword1(newValue) } else { setPassword2(newValue) }
    props.resetStatusMsg();
  }

  const onSubmit = (event) => {
    event.preventDefault();  // prevents page reload
    props.onSubmit(password1);
  }

  const renderPasswords = (isPassword1) => {
    const validation = password2.length >= password1.length && password1 !== password2
    const validationMsg = validation ? "Passwords do not match." : ""
    return (
      <div className="password-container-wrapper">
        <PasswordInput
          value={isPassword1 ? password1 : password2}
          className={isPassword1 ? ' password1' : ' password2'}
          onChange={handlePasswordChange}
          isPassword1={isPassword1}
        />
        {isPassword1 && <ErrorMsg statusMsg={props.statusMsg}/>}
        {isPassword1 && <div style={{height: '1px'}}/>}
        {!isPassword1 && <p className={`password-validation${VARIANT} ${validation}`}>{validationMsg}</p>}
      </div>
    )
  }

  const renderButtons = () => {  // "Back" button only exists for update flow
    const submitBtn = (
      <button
        data-testid="updatePass-submit-button"
        disabled={!(validationChars && validationMatch)}
        className={"proceed" + VARIANT} type="submit">Update Password
      </button>
    )
    if (props.onBack) {
      return (
        <div className="vertical-splitter">
          {submitBtn}
          <button data-testid="updatePass-cancel-button" className={"cancel" + VARIANT} type="button" onClick={props.onBack}>Back</button>
        </div>
      )
    }
    return submitBtn;
  }

  return (
    <form onSubmit={onSubmit}>
      <p className={"view-header" + VARIANT}>Update Password</p>
      <p
        className={"view-title" + VARIANT}
        style={{marginBottom: '4px'}}
      >
        Passwords must be at least 8 characters long.
      </p>
      {/* <StatusMsg statusMsg={props.statusMsg}/> */}
      {renderPasswords(true)}
      {renderPasswords(false)}
      {renderButtons()}
    </form>
  )
}

export default UpdatePasswordView;