import React, { useState } from "react";
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';

import StatusMsg from "../../StatusMsg/StatusMsg";

import './UpdatePhoneView.css';
import './UpdatePhoneViewAlt.css';
import 'react-phone-number-input/style.css';
import { VARIANT } from "../../../misc/constants";

const UpdatePhoneView = (props) => {
  const [sentCode, setSentCode] = useState(false);
  const [resentCode, setResentCode] = useState(false);
  const phone = props.phone
  const setPhone = props.setPhone

  const getParsedPhone = () => {
    const parsedPhone = parsePhoneNumber(phone);
    return `+${parsedPhone.countryCallingCode} ${parsedPhone.nationalNumber}`;
  }

  const sendSMS = (event) => {
    event.preventDefault();  // prevents page reload
    props.sendSMS(getParsedPhone());
    setSentCode(true);
    if(sentCode) setResentCode(true)
  }

  return (
    <div>
      <div>
        <p className={"view-header" + VARIANT}>Update Phone Number</p>
        {/* TODO: I don't like how I had to shrink and center this */}
        <p className={"view-title" + VARIANT} style={{maxWidth: '400px', paddingLeft: '55px'}}>Enter your phone number and click &quot;Send SMS&quot; to get an MFA code.</p>
        <StatusMsg statusMsg={props.statusMsg} resentCode={resentCode}/>
        <form className="phone-form" onSubmit={sendSMS}>
          <PhoneInput
            style={{width: '80%'}}
            placeholder="Phone number"
            value={phone}
            onChange={setPhone}
            required
            autoFocus={!sentCode}
            international={true}
            addInternationalOption={false}
            countryCallingCodeEditable={true}
            defaultCountry={props.country || 'US'}
            data-testid="phone-number-field"
            className={VARIANT}
          />
          <button data-testid="updatePhone-sms-button" className={"proceed" + VARIANT} disabled={!phone || !isValidPhoneNumber(phone)}>Send SMS</button>
          <button data-testid="updatePhone-back-button" className={"cancel" + VARIANT} onClick={props.onBack}>Back</button>
        </form>
      </div>
    </div>
  )
}

export default UpdatePhoneView;