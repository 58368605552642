import React, { useRef, useState } from "react";
import { BsPersonCircle } from 'react-icons/bs';
import { GrClose } from 'react-icons/gr';
import { AiOutlineClose } from "react-icons/ai";

import StatusMsg from "../../StatusMsg/StatusMsg";

import './PasswordView.css';
import './PasswordViewAlt.css';
import PasswordInput from "../../PasswordInput/PasswordInput";
import AudienceMsg from "../../AudienceMessage/AudienceMsg";
import ErrorMsg from "../../ErrorMsg/ErrorMsg";
import { VARIANT } from "../../../misc/constants";

function PasswordView(props) {
  const passwordTypeText = props.isTemp ? 'Temporary Password' : "Password"
  const [password, setPassword] = useState('');

  const disabledButton = password.length <= 0

  const onSubmit = (event) => {
    event.preventDefault();  // prevents page reload
    props.onSubmit(password);
  }

  const handleChange = (event) => {
    const newValue = event.target.value;
    setPassword(newValue);
    props.resetStatusMsg();
  }

  const renderForgotPassword = () => {
    return <p className={"forgot-pwd" + VARIANT} onClick={props.onForgot}>Forgot Password?</p>
  }

  const renderTempTitle = () => {
    return (
      <div>
        <p
          className="view-title"
          style={{
            marginTop: props.isTemp ? '-8px' : undefined,
            marginBottom: props.isTemp ? '0px' : undefined
          }}
        >
          If you cannot find your temporary password from accounts@surgicalsafety.com in
          your inbox, please request that your administrator reset your account.
        </p>
      </div>
    )
  }

  return (
    <form onSubmit={onSubmit}>
      <p className={"view-header" + VARIANT}>{`${!props.isTemp ? 'Enter Your ' : ''}${passwordTypeText}`}</p>
      {props.isTemp ? renderTempTitle() : null}
      <div className='email-pill-wrapper'>
        <div className={'email-pill-container' + VARIANT}>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M9.99996 1.66699C5.39996 1.66699 1.66663 5.40033 1.66663 10.0003C1.66663 14.6003 5.39996 18.3337 9.99996 18.3337C14.6 18.3337 18.3333 14.6003 18.3333 10.0003C18.3333 5.40033 14.6 1.66699 9.99996 1.66699ZM5.89163 15.2337C6.24996 14.4837 8.43329 13.7503 9.99996 13.7503C11.5666 13.7503 13.7583 14.4837 14.1083 15.2337C12.975 16.1337 11.55 16.667 9.99996 16.667C8.44996 16.667 7.02496 16.1337 5.89163 15.2337ZM15.3 14.0253C14.1083 12.5753 11.2166 12.0837 9.99996 12.0837C8.78329 12.0837 5.89163 12.5753 4.69996 14.0253C3.84996 12.9087 3.33329 11.517 3.33329 10.0003C3.33329 6.32533 6.32496 3.33366 9.99996 3.33366C13.675 3.33366 16.6666 6.32533 16.6666 10.0003C16.6666 11.517 16.15 12.9087 15.3 14.0253ZM9.99996 5.00033C8.38329 5.00033 7.08329 6.30033 7.08329 7.91699C7.08329 9.53366 8.38329 10.8337 9.99996 10.8337C11.6166 10.8337 12.9166 9.53366 12.9166 7.91699C12.9166 6.30033 11.6166 5.00033 9.99996 5.00033ZM9.99996 9.16699C9.30829 9.16699 8.74996 8.60866 8.74996 7.91699C8.74996 7.22533 9.30829 6.66699 9.99996 6.66699C10.6916 6.66699 11.25 7.22533 11.25 7.91699C11.25 8.60866 10.6916 9.16699 9.99996 9.16699Z" fill="#121926"/>
          </svg>
          <div className="email-pill-email">{props.email}</div>
          <div className={"cross-wrapper" + VARIANT} onClick={props.onWrongEmail}><AiOutlineClose size={20}/></div>
        </div>
      </div>
      <div style={{height: '15px'}}/>
      {/* <StatusMsg statusMsg={props.statusMsg}/> */}
      <AudienceMsg audienceMsg={props.audienceMsg} />
      <div style={props.isTemp ? {height: '38px'} : {height: '60px'}}/>
      <PasswordInput
        value={password}
        onChange={handleChange}
        isTemp={props.isTemp}
        statusMsg={props.statusMsg}
      />
      <ErrorMsg statusMsg={props.statusMsg}/>
      {!props.isTemp ? renderForgotPassword() : null}
      <div style={props.isTemp ? {height: '13px'} : {height: '58px'}}></div>
      <button
        data-testid={"password-proceed-button"}
        className={"proceed" + VARIANT}
        type="submit"
        disabled={disabledButton}
      >
        Next
      </button>
    </form>
  )
}

export default PasswordView;
